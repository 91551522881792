* {
  margin: 0px;
  padding: 0px;
}
#root {
  min-height: 100vh;
  background-color: #f6f6f6;
}
body {
  color: #222222;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 16px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 14px;
}
span {
  font-family: inherit;
}
